(function () {
    var GoogleMapsLoader = require('google-maps');
    GoogleMapsLoader.KEY = "AIzaSyDxXCanzlWH9xj8L11JvO7ERENuMFs8cBY";
    GoogleMapsLoader.load(function (google) {
        var map;
        function initialize() {
            var glat = 51.211237;
                glong = 3.219574;

            var mapOptions = {
                zoom: 17,
                scrollwheel: false,
                center: new google.maps.LatLng(glat, glong)
            };
            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                map: map,
                icon: '/bundles/sitefrontend/img/pointer.svg',
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();
